.swiper-pagination-bullet {
  height: 14px;
  width: 14px;
  background-color: #fff;
  position: relative;
  height: 20px;
  width: 20px;
  border: solid 1px black;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  &:after {
    content: '';
    position: absolute;
    height: 14px;
    width: 14px;
    background-color: var(--color-veritone-blue);
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-7px) translateY(-7px);
  }
}
